.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body{
  background-color:#EAEDED;
  background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAAXAAA/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAgEBAQECAgICAgICAgMCAgICAgIDAwMDAwMDAwQEBAQEBAYGBgYGBwcHBwcHBwcHBwEBAQECAgIEAwMEBgUEBQYHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcH/8AAEQgAgACAAwERAAIRAQMRAf/EAG4AAQEBAAAAAAAAAAAAAAAAAAAJCgEBAQEBAAAAAAAAAAAAAAAAAAQGAxAAAQMEAQQDAAAAAAAAAAAAAAECBAMFBgcIEiITFBEXCREBAAIBAgQHAAAAAAAAAAAAAAECEQMEIUEFBjFRYYGRsSP/2gAMAwEAAhEDEQA/ANI51cgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABPLlFz20vrLDdsYXj2aTfu+1WKVY7Hi1Wz3yNVo3KQ316NdZUmHTio2OlT2EVaqI9re1V6m/Ok6V2/r6t6Wmv5zOZnMeHzn0R7jd1rExnicXee2l9m4bqfC8hzSb933WxRbHfMWpWe+Sata5R2+vWrpKjQ6kVWyEp+wqpVVGNd3KnS74dV7f19K97RX84nMTmPD5z6G33dbREZ4qGmbWAAAAAAAAAAAAAAAEsf0B405jyYzvT+Ha4wyzxbjEjzbtnu27vR8FG327rp0IMGpMY1XyXOf53tjs6nIqNXta5XGs7d6pTa6d7XtOOVfOec/XFBvNCbzERHufn9xpzHjPne4MO2PhlnlXGXHhXbAtt2ij56Nwt3XUoToNOY9qPjOa/wAD3R39LlVXL3Najh3F1Sm606Wpacc6+U8p++Js9CaTMTHuqcZNeAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAE8uUXPbS+ssN2xhePZpN+77VYpVjseLVbPfI1WjcpDfXo11lSYdOKjY6VPYRVqoj2t7VXqb86TpXb+vq3paa/nM5mcx4fOfRHuN3WsTGeJxd57aX2bhup8LyHNJv3fdbFFsd8xalZ75Jq1rlHb69aukqNDqRVbISn7CqlVUY13cqdLvh1Xt/X0r3tFfzicxOY8PnPobfd1tERnioaZtYAAAAAAAAAAAAAAASx/QHjTmPJjO9P4drjDLPFuMSPNu2e7bu9HwUbfbuunQgwakxjVfJc5/ne2Ozqcio1e1rlcazt3qlNrp3te045V855z9cUG80JvMREe5+f3GnMeM+d7gw7Y+GWeVcZceFdsC23aKPno3C3ddShOg05j2o+M5r/A90d/S5VVy9zWo4dxdUputOlqWnHOvlPKfvibPQmkzEx7qnGTXgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/2Q==);
}

.col-sm-9{
  background-color:#FFF;
  max-width:800px;
}

.longwidth{
  max-width:1200px !important;
}

.HeadNavi{
  color: #FFF !important;
  background-color:#272727 !important;
  height:60px;
}

.nav-link{
  color:#FFF !important;
  text-decoration:none !important;
  }

h1{
  color:#FFF !important;
  text-decoration:none !important;
  }

.width100 {
  width:100%;
}

.width90 {
  width:90%;
}

.padding10 { 
  margin: 30px; 
  padding: 10px; 
}

.padding5 { 
  margin: 15px; 
  padding: 5px; 
}

.middle{
  text-align:center;
}

.cardTitle {
  background-color:#EEEEEE;
  width: 36rem;
}

.widthrem36 {
  width: 36rem;
}

.row {
  margin-top: 10px;
}

.Menu {
  border: 1px solid rgba(0,0,0,.125);
}

.MenuItem {
  color : #6c757d !important;
}

.MenuItem.nav-link.active {
  background-color:#fdead5 !important;
  color:#ff7200 !important;
}

.inline {
  display: inline-block;
  _display: inline;
}

Table.Fixed {
  table-layout: fixed;
}

.h1disc {
  font-size:24px;
  font-size: 2.0rem;
  font-weight :700;
  color:#14c3dc;
}

img {
  border: 1px #000000 solid;
  }

aside {
    position: relative;
    padding: 30px 15px 8px 15px;
    box-sizing: border-box;
    font-style: italic;
    background: #dcf6ff;
    color: #555;
}

aside p {
    padding: 0;
    margin: 10px 0;
    line-height: 1.7;
}

aside cite {
    display: block;
    text-align: right;
    color: #888888;
    font-size: 0.9em;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

